import PlayGame from '../scenes/PlayGame';

export default class GameButton extends Phaser.GameObjects.Container {
  constructor({scene = null,
      x = 0,
      y = 0,
      w,
      h,
      text = "",
      radius,
      clickFunction,
      clickFunctionData,
      disabled = false,
      disabledAlpha = .4,
      enabledAlpha = 1,
      xText = w / 2,
      yText = h / 2,
      textShadow = null, // '#000000' for black
      visible = true,
      font = "32px Arial",
      fontColor = "#ffca3a",
      addToScene = true,
      selected = false,
      alpha = 1,
      depth = 1000,
      parentContainer = null,
      borderWidth = 3,
      bgAlpha = 1,
      labelText = null,
      selectedBorderColor = '0xffca3a' // '0x03b000'
    }) {
    super(scene);
    this.scene = scene;
    this.clickFunction = clickFunction;
    this.clickFunctionData = clickFunctionData;
    this.disabled = disabled;
    this.selected = selected;
    this.disabledAlpha = disabledAlpha;
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.alpha = alpha;
    this.radius = radius;
    this.depth = depth;
    let g = new Phaser.GameObjects.Graphics(scene);
    g.fillStyle(0x540016, 1); // 0x5c3709 // 0x3e1baf
    g.fillRoundedRect(0, 0, w, h, radius);
    g.lineStyle(borderWidth, selectedBorderColor, 1);
    g.strokeRoundedRect(0, 0, w, h, radius);
    g.setAlpha(bgAlpha);
    let selectedGraphics = new Phaser.GameObjects.Graphics(scene);
    selectedGraphics.lineStyle(7, selectedBorderColor, 1);
    selectedGraphics.strokeRoundedRect(0, 0, this.w, this.h, this.radius);
    selectedGraphics.setVisible(false);
    selectedGraphics.setName("selectedStyle");
    let t = new Phaser.GameObjects.Text(scene, xText, yText, text, {font: font, color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    if (textShadow != null) {
      t.setShadow(3,3,textShadow,3,true)
    }
    this.text = t;
    t.setOrigin(0.5);
    g.setInteractive(new Phaser.Geom.Rectangle(0,0,w,h), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{ await this.click();});
    this.setVisible(visible);
    this.add(g);
    this.add(t);
    this.add(selectedGraphics);
    selected && this.select();

    if (labelText) {
      this.textLabelText = new Phaser.GameObjects.Text(scene, 6, 4, labelText, {font: '16px Arial', color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
      this.textLabelText.setOrigin(0,0);
      this.textLabelText.setDepth(depth - 1);
      this.add(this.textLabelText);
    }
    if (addToScene && parentContainer == null) {
      scene.add.existing(this);
    }
    if (parentContainer) {
      parentContainer.add(this);
    }
    
  }


  select() {
    this.selected = true;
    this.getByName("selectedStyle").setVisible(true);
  }

  unselect() {
    this.selected = false;
    this.getByName("selectedStyle").setVisible(false);
  }

  disable(disable) {
    this.disabled = disable;
    if (disable) {
      this.setAlpha(this.disabledAlpha);
    }
    else {
      this.setAlpha(this.enabledAlpha);
    }
  }

  async click(args) {
    this.unselect();
    let self = this;
    this.scene.tweens.addCounter({
      from: 100,
      to: 75,
      duration: 200,
      yoyo: true,
      onUpdate: tween => {
        if (!self.disabled) {
          let t = Math.floor(tween.getValue());
          self.setAlpha(t/100);
        }
      }
    }).play();
    await this.clickFunction.call(this.scene, args, this.clickFunctionData);
  }

}