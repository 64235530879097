// import PlayGame from '../scenes/PlayGame';
import ProgressBar from '../classes/ProgressBar';

export default class PlayerInfo extends Phaser.GameObjects.Container {
  constructor({scene = null,
               x = 0,
               y = 0,
               text = "Guest",
               depth = 1200000,
               duration = 300,
               origin = {x: 1, y: 0},
               width = 400,
               height = 134,
               radius = 5,
               clickFunction = null, 
               clickFunctionData = null,
               font = "20px Arial Black",
               fontColor = "#ffffff",
               loginText = '', // 'Change / Login',
               fontColorShadow = "#000000",
               avatar = "blankavatar"
              }) {
    super(scene);
    this.x = x;
    this.y = y;
    this.scene = scene;
    this.clickFunction = clickFunction;
    this.clickFunctionData = clickFunctionData;
    this.setDepth(depth);
    scene.add.existing(this);

    // let playerInfoBox = scene.add.rectangle(0, scene.game.config.height, scene.game.config.width, height - 6, 0xffca3a);
    // playerInfoBox.setOrigin(0);
    // playerInfoBox.setDepth(depth - 9); // to be above mainBox
    // this.add(playerInfoBox);

    this.setInteractive(new Phaser.Geom.Rectangle(200,0,400,250), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{
      
    });
    // let g = new Phaser.GameObjects.Graphics(scene);
    // g.fillStyle(0x383838, 1); // 0xffca3a = gold, 0x540016 = maroon, 0x383838 = dark gray
    // g.fillRoundedRect(0, 0, width, height, radius);
    // g.lineStyle(3, 0xffca3a, 1);
    // g.strokeRoundedRect(0, 0, width, height, radius);
    // g.setDepth(depth - 10);
    // this.add(g);
    // g.setInteractive(new Phaser.Geom.Rectangle(-(box.w / 2),-(box.h / 2),box.w,box.h), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{ this.destroy(); });

    // TODO add blankavatar when Guest (or not selected)
    this.avatar = new Phaser.GameObjects.Image(scene, width - 20, 25, avatar);
    this.avatar.setOrigin(1, 0);
    this.avatar.setScale(.44);
    this.avatar.setDepth(depth - 5);
    this.add(this.avatar);
    this.avatar.setInteractive(new Phaser.Geom.Rectangle(-100,-100,width,height+300), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{ 
      // if (scene.scene.get('Settings')) {
      scene.scene.get('Settings').input.topOnly = false;
      // }
      scene.scene.run('Settings');
      scene.scene.setVisible(true, 'Settings');
      scene.scene.bringToTop('Settings');
    })


    const level = scene.game.gameCurrentLevel;
    const levelSize = level.toString().length;
    let levelBoxWidth = 8 + levelSize * 15;
    let levelBoxHeight = 30;
    let levelBoxX = width - 110 - (levelSize * 15);
    let levelBoxY = (this.avatar.height / 2) - 20;
    this.levelBox = new Phaser.GameObjects.Graphics(scene);
    this.levelBox.fillStyle(0x540016, 1); // 0xffca3a = gold, 0x540016 = maroon, 0x383838 = dark gray
    this.levelBox.fillRoundedRect(levelBoxX, levelBoxY, levelBoxWidth, levelBoxHeight, 10);
    this.levelBox.setAlpha(.7);
    this.levelBox.setDepth(depth - 3);
    this.add(this.levelBox);

    this.levelText = new Phaser.GameObjects.Text(scene, levelBoxX + (levelBoxWidth / 2), levelBoxY + (levelBoxHeight / 2), level, {font: '26px Arial', color: '#ffca3a', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.levelText.setShadow(3, 3, '#000000', 1, true);
    this.levelText.setOrigin(0.5);
    this.levelText.setDepth(depth - 1);
    this.add(this.levelText);

    // ⚙
    this.gearIcon = new Phaser.GameObjects.Text(scene, levelBoxX + (levelBoxWidth / 2.2), levelBoxY - 52, "⚙", {font: '59px Arial', color: '#ffca3a', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.gearIcon.setShadow(3, 3, '#000000', 1, true);
    this.gearIcon.setOrigin(0.5);
    this.gearIcon.setDepth(depth - 1);
    this.gearIcon.setPadding(5);
    this.add(this.gearIcon);

    let scoreTextX = width - 175;
    this.scoreTextTitle = new Phaser.GameObjects.Text(scene, scoreTextX, 30, 'score', {font: '29px Arial', color: '#ffffff', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.scoreTextTitle.setShadow(2, 2, '#000000', 1, true);
    this.scoreTextTitle.setOrigin(1,0);
    this.scoreTextTitle.setDepth(1000001);
    this.scoreTextTitle.setVisible(false);
    this.scoreTextTitle.setPadding(3);
    this.add(this.scoreTextTitle);
    this.scoreText = new Phaser.GameObjects.Text(scene, scoreTextX, 55, '0', {font: '69px Arial Black', color: '#ffffff', boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.scoreText.setShadow(2, 2, '#000000', 1, true);
    this.scoreText.setOrigin(1,0);
    this.scoreText.setDepth(1000001);
    this.scoreText.setPadding(3);
    this.add(this.scoreText);

    this.scoreVisible = function (enable) {
      this.scoreText.setVisible(enable);
      this.scoreTextTitle.setVisible(enable);
    }
    this.scoreVisible(false); // hide score info at creation

    this.levelProgressBar = new ProgressBar({scene: scene,
      x: width - 28,
      y: levelBoxY + levelBoxHeight,
      width: 88,
      height: 18,
      addToScene: false
    });
    this.levelProgressBar.setRotation(Math.PI * -.5); // vertical upright
    this.add(this.levelProgressBar);

    this.loginText = new Phaser.GameObjects.Text(scene, width - 15, height - 35, loginText, {font: '30px Arial', color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    this.loginText.setOrigin(1,0);
    this.loginText.setDepth(depth - 1);
    this.add(this.loginText);
    // player name
    let t = new Phaser.GameObjects.Text(scene, width - 25, -3, text, {font: font, color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    t.setShadow(3, 3, fontColorShadow, 3, true);
    t.setOrigin(origin.x, origin.y);
    t.setDepth(depth - 1);
    t.setPadding(5);
    this.add(t);
    this.text = t;

      // this.depth = depth;
      
      // console.log(this);
      
      // left for reference but for now ths doesn't need to "popin" every time it loads
      // scene.tweens.chain({
      //   targets: [this],
      //   tweens: [{
      //       scale: 1.25,
      //       ease: 'Expo',
      //       duration: duration,
      //       repeat: 0,
      //       yoyo: true
      //     },
      //     { scale: 1,
      //       ease: 'Expo',
      //       duration: 150,
      //       yoyo: false,
      //       completeDelay: 100
      //   }]
      // }).play();
  }

  // TODO auto scaling of name
  setName(name) {
    this.text.setText(name);
  }

  click(args) {
    this.clickFunction.call(this.scene, args, this.clickFunctionData);
  }

  // this can be used to overwrite the default destroy() functionality
  // sometimes we have to cleanup other items from here (such as do a clearInterval() etc)
  // destroy() {
  //   super.destroy();
  // }

}