import _ from 'lodash';
import {NativeAudio} from '@capacitor-community/native-audio'
import Card from './Card'

const moveStyles = {
  default: {
    ease: 'Expo',
    zEase: 'Expo',
  },
  drop: {
    ease: 'Expo',
    zEase: 'Bounce.easeOut',
  },
  toss: {
    ease: 'Expo',
    zEase: 'Expo.easeIn',
  },
  draw: {
    ease: 'Back.easeIn',
    zEase: 'Expo.easeOut',
  },
  yank: {
    ease: 'Expo',
    zEase: 'Elastic.easeOut',
  },
  zoom: {
    ease: 'Expo',
    zEase: 'Elastic.easeIn',
  },
  shake: {
    ease: 'Elastic',
    zEase: 'Elastic.easeOut',
  },
};

export default class CardSet extends Phaser.GameObjects.Group {

  constructor({
    scene,
    x = 0,
    y = 0,
    z = 0, // cards' modelRotation z axis...  set to negative value to make smaller cards
    depth = 0,
    stepStack = null, // If provided will step x+y offsets (used to create illusion of stack of cards)
    name = 'Untitled', // Used for debugging 
    xSpread = 0, // Horizontal distance between cards in pixels
    ySpread = 0, // Vertical distance between cards in pixels
    xSpreadMin = null, // Horizontal distance between cards minmum to compress when exceeding maxWidth
    ySpreadMin = null, // Vertical distance between cards minmum to compress when exceeding maxHeight
    xSelectOffset = 0, // How much the currently selected card is offset rightward in pixels
    ySelectOffset = 0, // How much the currently selected card is offset upwards in pixels
    maxWidth = null, // Maximum total width cards should spread, xSpreadMin nowithstanding
    maxHeight = null, // Maximum total height cards should spread, ySpreadMin nowithstanding
    angle = 0, // How much the entire CardSet object is rotated in degrees
    angleCard = 0, // How much each card is progressively rotated in degrees
    angleCardRandom = 0, // How many degrees to randomize card rotation by
    positionTweenDuration = 150, // How long in ms to take to reposition cards
    positionTweenEase = 'Expo.easeOut', // What easing method to use to tween reposition of cards
    selectType = null, // Possible values will be null (no select) 'single' and TODO 'multi'
    cardSelectHandler = null, // Callback function when a card is selected
    addCardHandler = null, // Callback function when a card is added to this cardset
  }) {
    super(scene);
    this.x = x;
    this.y = y;
    this.z = z;
    this.depth = depth;
    this.scene = scene;
    this.name = name;
    this.xSpread = xSpread;
    this.ySpread = ySpread;
    this.xSpreadMin = xSpreadMin;
    this.ySpreadMin = ySpreadMin;
    this.xSelectOffset = xSelectOffset;
    this.ySelectOffset = ySelectOffset;
    this.stepStack = stepStack;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.angle = angle;
    this.angleCard = angleCard;
    this.angleCardRandom = angleCardRandom;
    this.selectCursorIdx = null;
    this.positionTweenDuration = positionTweenDuration;
    this.positionTweenEase = positionTweenEase;
    this.cardSelectHandler = cardSelectHandler;
    this.addCardHandler = addCardHandler;
    this.noActionAllowed = false; // if true, won't let moveCardToCardSet execute
    this.list = this.children.entries;
    this.seed = Math.random;
    scene.add.existing(this);
  }

  // Gets a card's expected position as an object 
  // as looked up by the card's index
  // TODO: calc y value based on an arc instead of linearly based on a 
  //       spreadType property
  // TOOD: calc final x+y for selected cards based off of not just a simple
  // offset, but based off of the angle of the card (tilted cards wil be
  // offset at the same tilt)
  cardPos(idx) {

    // Random number generator seeded by this object and the card index
    const randGen = new Phaser.Math.RandomDataGenerator([this.seed, idx])

    var xyIdx = idx;
    if (this.stepStack) {
      xyIdx = Math.floor(idx/this.stepStack) * this.stepStack;
    }

    const depth = this.depth + idx;

    var xWidth = (this.list.length - 1) * this.xSpread; // Total center-to-center of cards width
    var xSpread = this.xSpread; // Distance between card centers
    if (this.maxWidth !== null && xWidth > this.maxWidth) {
      xWidth = this.maxWidth;
      xSpread = this.maxWidth / (this.list.length - 1);
    }
    var xCenterOffset = 0 - (xWidth / 2);

    const xCardOffset = xyIdx * xSpread;
    var x = this.x + xCenterOffset + xCardOffset;
    
    var selectX = 0;
    if (this.selectCursorIdx !== undefined && idx === this.selectCursorIdx) {
      selectX = this.xSelectOffset;
    }

    var yHeight = (this.list.length - 1) * this.ySpread;
    var ySpread = this.ySpread;
    if (this.maxHeight !== null && yHeight > this.maxHeight) {
      yHeight = this.maxHeight;
      ySpread = this.maxHeight / (this.list.length - 1);
    }
    const yCenterOffset = 0 - (yHeight / 2);
    const yCardOffset = xyIdx * ySpread;
    var y = this.y + yCenterOffset + yCardOffset;

    var selectY = 0;
    if (this.selectCursorIdx !== undefined && idx === this.selectCursorIdx) {
      selectY = this.ySelectOffset;
    }
    
    var angleRand = 0; // Amount of random 2d (z-axis) rotation for this card
    if (this.angleCardRandom) {
      angleRand = (this.angleCardRandom * -0.5) + (randGen.frac() * this.angleCardRandom);
    }
    const angleCenterOffset = 0 - ((this.list.length - 1) * this.angleCard / 2);
    const angleCardOffset = idx * this.angleCard;
    const angle = this.angle + angleCenterOffset + angleCardOffset + angleRand;

    return {
      x: x,
      y: y,
      z: this.z,
      selectX: selectX,
      selectY: selectY,
      angle: angle,
      depth: depth,
    };
  }

  // Returns true or false depending on if the card is in the position it should
  // be in already
  isCardInPosition(idx, expectedPos=null) {
    expectedPos ??= this.cardPos(idx);
    return _.isEqual( expectedPos, this.list[idx].pos )
  }

  // Returns true or false depending on if a card is selected
  hasCardSelected() {
    return this.selectCursorIdx != null;
  }

  // Moves a card to the position it should be in (if needed)
  positionCard({
    idx=null,
    duration=null,
    ease=null,
  }) {
    duration ??= this.positionTweenDuration;
    ease ??= this.positionTweenEase;
    const card = this.list[idx];
    if (card.inTransit) { return; } // Don't position cards that are being moved into this CardSet
    const pos = this.cardPos(idx)
    if ( this.isCardInPosition(idx, pos) ) {
      return; // Bail if the card is already in position, no need to change
    }
    if (!duration) {
      // Instantly reposition
      card.x = pos.x;
      card.y = pos.y;
      card.angle = pos.angle;
      card.depth = pos.depth;
      card.modelPosition.z = pos.z;
      card.modelPosition.x = pos.selectX;
      card.modelPosition.y = pos.selectY;
    }
    else {
      // Tween reposition
      this.scene.tweens.add(
        { 
          targets: card,
          x: pos.x,
          y: pos.y,
          angle: pos.angle,
          depth: pos.depth,
          duration: duration,
          ease: ease,
        }
      );
      this.scene.tweens.add(
        { 
          targets: card.modelPosition,
          x: pos.selectX,
          y: pos.selectY,
          z: pos.z,
          duration: duration,
          ease: ease,
        }
      );
      //this.scene.tweens.add();
    }
  }

  // Loops over all the cards in the set, and moves them into proper place.
  // This should be called any time the cards listed in this CardSet have been modified
  positionAllCards({ duration=null, ease=null }) {
    duration ??= this.positionTweenDuration;
    ease ??= this.positionTweenEase;
    this.list.forEach( (card, idx) => {
      if (!card.inTransit) { // Don't position cards that are being moved into this CardSet
        this.positionCard({ idx, duration: duration, ease: ease })
      }
    })
  }

  shuffleCards() {
    this.selectCursorClear({});
    this.shuffle();
    this.positionAllCards({});
  }

  // Swaps two cards with each other by card index
  swapCards(idx1, idx2) {
    [ this.list[idx1], this.list[idx2] ] = [ this.list[idx2], this.list[idx1] ]
    // TODO maybe tween these so one goes over and the other goes under?
    this.positionAllCards({});
  }

  getTopCard() {
    const lastIdx = this.list.length;
    return this.list[lastIdx - 1];
  }

  // Adds a card to a CardSet
  addCard({card, idx=null, fromCardSet=null, handlerDelay=0}) {
    var newIdx = null;
    if (idx === null) {
      // Add card to end
      const lastIdx = this.list.length;
      this.add(card);
      if (this.list[lastIdx] === card) { newIdx = lastIdx; }
    } 
    else {
      this.list.splice(idx, 0, card);
      if (this.list[lastIdx] === card) { newIdx = idx; }
    }
    if (newIdx === null) {
      // list must have been modified since adding, search for the card's index
      newIdx = this.getIdxByCard(card);
    }
    this.addCardHandler && (this.addCardHandler.call(this, {fromCardSet: fromCardSet, handlerDelay: handlerDelay, card: card}));
    this.positionAllCards({}); // Move cards to their final position
    // Note: this could possibly be null if the card has already been removed
    // immediately after this add
    return newIdx;
  }

  // Note this may not work as expected right now
  getIdxByCard(card) {
    this.list.forEach( (idx, checkCard) => {
      if (checkCard === card) { return idx; }
    });
    return null; // null if card not present
  }

  getIdxByCardId(cardId) {
    const idx = this.list.findIndex(card => card && card.card === cardId);
    return idx;
    // for returning the object: this.list.find(({card, idx}) => card == cardId);
  }

  // Removes a card from a set
  removeCard(idx) {
    const [card] = this.list.splice(idx, 1);
    if (this.selectCursorIdx !== null && this.selectCursorIdx >= this.list.length) {
      // Correct the cursor if we removed the last card
      this.selectCursorSet({ dir: 'left' });
    }
    // the following is what causes the discardQueue to realign awkardly
    this.positionAllCards({}); // Move all cards to their final positions via tween
    return card;
  }

  moveAllCardsToCardSet({
    toCardSet, // Target CardSet object
    delay = 0, // How much to delay additionally between cards
    bottomUp = true, // Process idx 0 first (if false, last idx first)
    postCall = () => {}, // call to be made at the completion of the last move
    moveArguments = {},
    dynamicDiscardSounds = false
  }) {
    moveArguments = {
      spin: 3,
      delay: 0,
      depth: 0,
      postCall: () => {},
      ...moveArguments
    }
    const cardsToMove = this.list.length;
    while (this.list.length) {
      let movePostCall = moveArguments.postCall;
      if (this.list.length == 1) {
        movePostCall = () => {
          moveArguments.postCall.call(this);
          postCall.call(this);
        };
      }
      const currCard = cardsToMove - this.list.length;
      const moveDelay = moveArguments.delay + 
        ((cardsToMove - this.list.length) * delay);
      if (dynamicDiscardSounds && currCard > 0) { // don't play a sound for original top card
        this.scene.time.delayedCall(moveDelay, () => {
          this.scene.game.playSound(`discard${currCard}`);
        });
      }
      this.moveCardToCardSet({
        ...moveArguments,
        idx: bottomUp ? 0 : null,
        toCardSet: toCardSet,
        delay: moveDelay,
        postCall: movePostCall,
      });
    };
  };

  // Moves a card from this CardSet into another target CardSet object
  moveCardToCardSet({
    toCardSet, // Target CardSet object
    idx = null, // Index of Card to move, defaults to selected or last card
    toIdx = null, // Will default to one more than last card in target if not set
    faceUp = false, // What way up the card will be oriented at the end of the move
                    // Setting this to null will keep the card in whatever orientation it's
                    //     currently in
    cardId = null, // set the cardId (used when only card backs are set in online games as we don't really know what an opponent holds on the client)
    select = false, // Make the new card the selected one in the targt CardSet
    duration = 600, // How long to take doing the movement, 0 will move instantly
    delay = 0, // How long to wait before executing the tween
    addHandlerDelay = 0, // delay in ms to pass to the add card function
    style = 'default',
    startSound = true,
    startSoundKey = 'whoop2',
    flipDuration = 300, // How long to take flipping the card
    flipDelay = 200, // How long to wait before flipping the card
    depth = 1000, // How much to increase over target depth (hack!)
    preCall = null, // Function to call before doing anything
    postCall = null, // Function to call after tween is done
    spin = 0, // Number of clockwise rotations to spin the card (use negative for counter clockwise)
    // liftScale = 1.5, // TODO a second scale tween to make the card look like it's lifting as it moves
  }) {
    if (this.noActionAllowed) { return; } // player unable to take action
    if (idx === null) {
      // If no idx provided, use selectCursorIdx or the last card index
      if (this.selectCursorIdx !== null) { idx = this.selectCursorIdx; }
      else { idx = this.list.length - 1; }
    }
    if (select) { toCardSet.selectCursorClear({}); }
    const card = this.removeCard(idx);
    if (!card) { return; }
    ////////////////// TODO move the rest into Card?
    cardId && (card.card = cardId) && (card.cardAttributes = Card.parseCardIdToAttributes(cardId));
    preCall && preCall.call(this.scene, {cardId: card.card}); // call the pre "callback"
    card.inTransit = true;
    toIdx = toCardSet.addCard({card: card, idx: toIdx, fromCardSet: this, handlerDelay: addHandlerDelay});
    if (delay == 0 && duration == 0) {
      // Don't do a tween if we don't have to
      card.inTransit = false;
      if (select) { toCardSet.selectCursorSet({ idx: toIdx });  }
      else { toCardSet.positionAllCards({ duration: 0 }); }
      return;
    }
    const toPos = toCardSet.cardPos(toIdx);
    if (faceUp !== null) {
      card.flip({ faceUp: faceUp, duration: flipDuration, delay: flipDelay })
    }
    // this.scene.whoop.play(); // no overlap (aka it restarts)
    // this.scene.sound.play('whoop2', { volume: (Math.random() * (1-0.25) + 0.25), rate: (Math.random() * (4-0.75) + 0.75) })
    this.scene.tweens.add({
      targets: card,
      depth: toPos.depth + depth,
      ease: 'Expo.easeInOut',
      duration: duration,
      delay: delay,
    }).play();
    this.scene.tweens.add({
      targets: card,
      angle: toPos.angle + (spin * 360),
      x: toPos.x,
      y: toPos.y,
      ease: moveStyles[style].ease,
      duration: duration,
      delay: delay,
    }).play();
    this.scene.tweens.add({
      targets: card.modelPosition,
      x: toPos.selectX,
      y: toPos.selectY,
      ease: moveStyles[style].ease,
      duration: duration,
      delay: delay,
      onStart: () => {
        if (startSound) {
          // NativeAudio.play({
          //   assetId: startSoundKey,
          // });
          this.scene.game.playSound(startSoundKey);
        }
      }
    }).play();
    this.scene.tweens.add({
      targets: card.modelPosition,
      z: toPos.z,
      onComplete: () => {
        card.inTransit = false;
        if (select) { toCardSet.selectCursorSet({ idx: toIdx }); }
        else { toCardSet.positionAllCards({}); }
        postCall && postCall.call(this.scene, card, idx); // call the post "callback"
        if (faceUp === false) {
          card.cardId = null;
        }
      },
      ease: moveStyles[style].zEase,
      duration: duration + 5,
      delay: delay,
    }).play();
  }

  // Can set the select cursor position, and then corrects any errors with the
  // position by making sure it's set if currently null, is in bounds of the list,
  // is not an inTransit card, deferring left or right depending on the dir param
  selectCursorSet({ idx=null, dir='left', reposition=true }) {
    // Set to an explicit value if provided
    if (idx !== null) { this.selectCursorIdx = idx; }
    // Set to the end or beginning if there's no current cursor, depending on direction
    if (this.selectCursorIdx === null) {
      if (dir == 'left') { this.selectCursorIdx = this.list.length - 1 ; }
      else { this.selectCursorIdx = 0; }
    }
    // If the selected card is inTransit, keep incrementing/decrementing
    // until we find one that isn't or we hit a boundary (undefined)
    while (
      this.list[this.selectCursorIdx] !== undefined
      && this.list[this.selectCursorIdx].inTransit
    ) {
      if (dir == 'left') { this.selectCursorIdx--; }
      else { this.selectCursorIdx++; }
    }
    // Correct for out of bounds of the list
    if (this.selectCursorIdx > this.list.length - 1) {
      this.selectCursorIdx = this.list.length - 1;
    }
    if (this.selectCursorIdx < 0) {
      this.selectCursorIdx = 0 ;
    }
    this.cardSelectHandler && (this.cardSelectHandler.call(this));
    if (reposition) {
      this.positionAllCards({});
    }
    return this.selectCursorIdx;
  }

  selectCursorClear({ reposition=true }) {
    this.selectCursorIdx = null;
    if (reposition) {
      this.positionAllCards({});
    }
  }

  // The below methods could move the select cursor in either direction
  // depending on if xSpread and ySpread have negative or positive values, 
  // so think of "Left" to mean lower numbers in the array and "Right" 
  // to mean higher.

  selectCursorLeft() {
    if (this.selectCursorIdx !== null) {
      if (--this.selectCursorIdx < 0) {
        this.selectCursorIdx = this.list.length - 1; // loop to end
      }
    }
    // Correct / reset the cursor & reposition cards
    return this.selectCursorSet({ dir: 'left' });
  }

  selectCursorRight() {
    if (this.selectCursorIdx !== null) {
      if (++this.selectCursorIdx >= this.list.length) {
        this.selectCursorIdx = 0; // loop to beginning
      }
    }
    // Correct / reset the cursor & reposition cards
    return this.selectCursorSet({ dir: 'right' });
  }

  selectCursorSwapLeft() {
    if (this.selectCursorIdx === null) { return; }
    if (this.selectCursorIdx < 1) { return; }
    this.swapCards(this.selectCursorIdx, this.selectCursorIdx - 1);
    // Correct / reset the cursor & reposition cards
    return this.selectCursorSet({ idx: this.selectCursorIdx - 1, dir: 'left' });
  }

  selectCursorSwapRight() {
    if (this.selectCursorIdx === null) { return; }
    if (this.selectCursorIdx >= this.list.length - 1) { return; }
    this.swapCards(this.selectCursorIdx, this.selectCursorIdx + 1);
    // Correct / reset the cursor & reposition cards
    return this.selectCursorSet({ idx: this.selectCursorIdx + 1, dir: 'right' });
  }

}
