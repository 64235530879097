import PlayGame from '../scenes/PlayGame';

export default class ProgressBar extends Phaser.GameObjects.Container {
  constructor({scene = null,
               x = 0,
               y = 0,
               text = "", // default text to show in progress bar
               font = "30px Arial Black",
               fontColor = "#ffffff",
               fontColorShadow = "#000000",
               depth = 1200000,
               origin = {x: 0.5, y: 0.5},
               clickFunction = null, 
               clickFunctionData = null,
               width = 200,
               height = 50,
               radius = 5,
               borderWidth = 2,
               selectedBorderColor = 0xffca3a,
               addToScene = true,
               onComplete = ()=>{
                // do nothing by default
               }}) {
    super(scene);
    this.scene = scene;
    this.x = x;
    this.y = y;
    this.width = width;
    this.scene = scene;
    this.clickFunction = clickFunction;
    this.clickFunctionData = clickFunctionData;
    this.setDepth(depth);
    if (addToScene) {
      scene.add.existing(this);
    }
    ///// start of progress bar graphics
    let mainBox = new Phaser.GameObjects.Graphics(scene);
    mainBox.fillStyle(0x540016, 1); // 0x5c3709 // 0x3e1baf
    mainBox.fillRoundedRect(0, 0, width, height, radius);
    mainBox.setDepth(depth - 10); // bottom most object in this container
    mainBox.lineStyle(borderWidth, selectedBorderColor, 1);
    mainBox.strokeRoundedRect(0, 0, width, height, radius);
    this.add(mainBox);
    let mainProgressBar = scene.add.rectangle(3, 3, 1, height - 6, 0xffca3a);
    mainProgressBar.setOrigin(0);
    mainProgressBar.setDepth(depth - 9); // to be above mainBox
    this.add(mainProgressBar);
    this.bar = mainProgressBar;

    let barText = new Phaser.GameObjects.Text(scene, 0, 0, text, {font: font, color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
    barText.setShadow(3, 3, fontColorShadow, 3, true);
    // t.setScale(startScale);
    barText.setOrigin(origin.x, origin.y);
    barText.setDepth(depth + 1); // should be above everything else in this container
    this.add(barText);
  }

  setProgress({perc, onComplete = null, instant=false, duration = 700, reset = false}) { // 0-100 
    // console.log("PBsP", perc, duration, reset);
    if (reset) {
      if (this.bar.tween) {
        this.bar.tween.stop();
      }
      this.bar.displayWidth = (this.width - 6);
      // console.log("PBsP reset", this.width)
    }
    let newWidth = (this.width - 6) * (Math.min(Math.floor(perc)/100, 99));
    this.bar.tween = this.scene.tweens.add({
      targets: this.bar,
      displayWidth: Math.max(newWidth,0.5),
      duration: instant ? 0 : duration,
      ease: 'sine.inout',
      onComplete: (onComplete)
        ? ()=> { this.visible && onComplete.call(this.scene) }
        : ()=>{},
    });
  }

  click(args) {
    this.clickFunction.call(this.scene, args, this.clickFunctionData);
  }

}