import PlayGame from '../scenes/PlayGame';

export default class PopInText extends Phaser.GameObjects.Container {
  constructor({scene = null,
               x = 0,
               y = 0,
               text = "",
               startScale = .8,
               delay = 1,
               scaleBy = 1.5, 
               duration = 350, 
               depth = 1200000,
               origin = {x: 0.5, y: 0.5},
               clickFunction = null, 
               clickFunctionData = null,
               box = false, // {w, h, radius}
               font = "69px Arial Black",
               fontColor = "#ffffff",
               closeText = '', // 'tap to close', // no default to '' as it's the normal use case for now
               closeTextFont = '32px Arial',
               closeTextFontColor = "#ffffff",
               fontColorShadow = "#000000",
               onDestroy = null,
              //  fontColor = "#d5640e",
              //  fontColorShadow = "#ffffff",
               endScale = 0.05,
               onComplete = ()=>{
                 this.destroy();
               }}) {
    super(scene);
    this.x = x;
    this.y = y;
    this.scene = scene;
    this.closeText = null; // this is the object, not the string from the constructor
    this.clickFunction = clickFunction;
    this.clickFunctionData = clickFunctionData;
    this.onDestroy = onDestroy;
    this.setDepth(depth);
    scene.add.existing(this);
    scene.time.delayedCall(delay, () => {
      if (box) {
        let g = new Phaser.GameObjects.Graphics(scene);
        g.fillStyle(0x540016, 1); // 0x5c3709 // 0x3e1baf
        g.fillRoundedRect(-(box.w / 2), -(box.h / 2), box.w, box.h, box.radius);
        g.setAlpha((box.alpha) ? box.alpha : .75);
        g.setDepth(depth - 10);
        this.add(g);
        g.setInteractive(new Phaser.Geom.Rectangle(-(box.w / 2),-(box.h / 2),box.w,box.h), Phaser.Geom.Rectangle.Contains).on('pointerdown', async ()=>{ await this.click() && this.destroy(); });
        this.closeText = new Phaser.GameObjects.Text(scene, box.w / 2 - 15, (box.h / 2) - 24, closeText, {font: closeTextFont, color: closeTextFontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
        this.closeText.setOrigin(1,0);
        this.closeText.setDepth(depth - 1);
        this.closeText.setScale(.6);
        this.add(this.closeText);
      }
      let t = new Phaser.GameObjects.Text(scene, 0, 0, text, {font: font, color: fontColor, boundsAlignH: "center", boundsAlignV: "middle", align: 'center'});
      t.setShadow(3, 3, fontColorShadow, 3, true);
      t.setPadding(5);
      t.setScale(startScale);
      t.setOrigin(origin.x, origin.y);
      t.setDepth(depth - 1);
      this.add(t);

      // this.depth = depth;
      
      // console.log(this);
      
      scene.tweens.chain({
        targets: [this],
        tweens: [{
            scale: scaleBy,
            ease: 'Expo',
            duration: duration,
            repeat: 0,
            yoyo: true
          },
          { scale: endScale,
            ease: 'Expo',
            duration: 150,
            yoyo: false,
            completeDelay: 100,
            onComplete: onComplete
        }]
      }).play();
    });
  }

  click(args) {
    if (this.clickFunction) {
      this.clickFunction.call(this.scene, args, this.clickFunctionData);
    }
    else {
      this.destroy();
    }
  }

  _setCloseText(text) {
    try {
      this.closeText && this.closeText.setText(`${text}`);
    }
    catch (e) {
      // clearInterval(this.closeTextUpdateInterval);
      if (this.scene && this.scene.time.getEvent(this.closeTextUpdateInterval)) {
        this.scene.time.removeEvent(this.closeTextUpdateInterval);
      }
      // console.log(e, 'while setting text', text, this, this.closeText);
    }
  }

  // Updates the closeText text value every second for parameter seconds
  closeTextUpdater({ runForSeconds = 3, timeSecsPerInterval = 1, text = 'tap to close', destroyAfter = true, endCallback = null}) {
    let countOfIntervals = 0;
    this._setCloseText(`${text}(${runForSeconds - countOfIntervals})`);
    countOfIntervals++;
    if (this.scene) {
    this.closeTextUpdateInterval = this.scene.time.addEvent({
      delay: timeSecsPerInterval * 1000,
      callback: () => {
        if (this) {
          if (countOfIntervals * timeSecsPerInterval < runForSeconds) {
            this._setCloseText(`${text}(${runForSeconds - countOfIntervals})`);
            countOfIntervals++;
          }
          else {
            this.scene && this.scene.time.removeEvent(this.closeTextUpdateInterval);
            endCallback && endCallback.call(this);
            if (destroyAfter) {
              this.destroy();
            }
          }
        }
      },
      loop: true
      });
    }
  }

  async destroy() {
    // let's ensure we stop the close text update interval if destroying this object
    // if (this.closeTextUpdateInterval != null) {
    //   // clearInterval(this.closeTextUpdateInterval);
    //   this.scene.time.removeEvent(this.closeTextUpdateInterval);
    // }
    this.onDestroy && await this.onDestroy.call(this.scene);
    super.destroy();
  }

}