import Phaser from 'phaser';
import PlayGame from './scenes/PlayGame';
import Intro from './scenes/Intro';
import Settings from './scenes/Settings';
import GameOptions from './scenes/GameOptions';
import '../styles.css';
// import CircleMaskImagePlugin from 'phaser3-rex-plugins/templates/circlemaskimage-plugin.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import InputTextPlugin from 'phaser3-rex-plugins/plugins/inputtext-plugin';
import { NativeAudio } from '@capacitor-community/native-audio'
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { App } from '@capacitor/app';
import GameSelection from './scenes/GameSelection';
import PlayerInfo from './classes/PlayerInfo';

// const firebaseConfig = {
//   apiKey: "AIzaSyAf-r4_CGx6LiIbWjj5IuFYn_a0Iu2qMhY",
//   authDomain: "decknroll.firebaseapp.com",
//   projectId: "decknroll",
//   storageBucket: "decknroll.appspot.com",
//   messagingSenderId: "805670426190",
//   appId: "1:805670426190:web:e6fac0b1320c18179b951f",
//   measurementId: "G-PCZ6ZWXCBX"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// allows us to get mobile native like items such as Action Sheets
// see https://capacitorjs.com/docs/apis/action-sheet and https://capacitorjs.com/docs/web/pwa-elements 
// defineCustomElements(window);

let clientId = '805670426190-c2ebqj656hvkmt04rpbsfvjmdcfj05sh.apps.googleusercontent.com';
const platform = Capacitor.getPlatform();
if (platform == 'android') {
  clientId = '805670426190-f3d5d7sb423qff6kbu2crcqg251qa4sp.apps.googleusercontent.com'
}
else if (platform == 'ios') {
  clientId = ''; // TODO get an IOS oauth client ID
}

window.showLandscape = () => {
  return window.innerWidth > window.innerHeight;
}

const config = {
  type: Phaser.WEBGL, // card rendering doesn't work with CANVAS, force WEBGL
  scale: {
    parent: 'game-cont',
    mode: Phaser.Scale.FIT,// Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: window.showLandscape() ? 1280 : (1280 / (window.innerHeight) * window.innerWidth),
    // width: 2341, // 2334 (iphone 12 pro), // 2064 // ####### the larger the width the more memory is used on the GPU, 2341 caused iphone XS to crash chrome and safari
    height: window.showLandscape() ? (1280 / (window.innerWidth) * window.innerHeight) : 1280, // window.innerHeight * 2.5, // 1080
  },
  scene: [Intro, GameSelection, PlayGame, Settings, GameOptions],
  plugins: {
        scene: [{
            key: 'rexUI',
            plugin: UIPlugin,
            mapping: 'rexUI'
          },],
        global: [{
            key: 'rexInputTextPlugin',
            plugin: InputTextPlugin,
            start: true
        },],
        "CapacitorHttp": {
          "enabled": true
        },
        // "GoogleAuth": {
        //   "scopes": ["profile", "email"],
        //   "serverClientId": "805670426190-c2ebqj656hvkmt04rpbsfvjmdcfj05sh.apps.googleusercontent.com",
        // }
  },
  // audio: {
  //   disableWebAudio: true
  // }
};

const game = new Phaser.Game(config);
// window.game = game;

game.getMainWidth = () => {
  // console.log('getMainWidth', window.screen.orientation.type, window.innerWidth, window.innerHeight);
  return window.showLandscape() ? 1280 : (1280 / (window.innerHeight) * window.innerWidth);
}

game.getMainHeight = () => {
  return window.showLandscape() ? (1280 / (window.innerWidth) * window.innerHeight) : 1280;
}

/**
 * Sets the game size to the main width and height
 * Note this had to be called with a short delay after the window resize
 *  to prevent it from getting dimensions of the prior orientation
 * @param {*} scene 
 */
game.setGameSize = (scene, callback) => {
  // console.log('setGameSize before', game.getMainWidth(), game.getMainHeight());
  // console.log('more...', window.innerWidth, window.innerHeight);
  scene.scale.setGameSize(game.getMainWidth(), game.getMainHeight());
  game.setPositionConfig(scene);
  callback && callback.call(this, scene);
  // console.log('setGameSize after', game.getMainWidth(), game.getMainHeight());
}

game.clientId = clientId;

game.restartMusic = function(assetId = 'd64-theme') {
  if (game.gameAudio == PlayGame.gameAudio[0].mode) {
    NativeAudio.isPlaying({ 
      assetId: assetId
    }).then((v)=> {
      if (!v.isPlaying) {
        game.playSound(assetId, true);
      }
    }).catch((e) => {
      game.playSound(assetId, true);
    });
  }
}

// TODO replace hardcoded d64-theme with reference to current theme music assetId
App.addListener('resume', data => {
  // console.log('App listener resume:', data);
  game.restartMusic('d64-theme');
});

App.addListener('appStateChange', ({ isActive }) => {
  // console.log('App state changed. Is active?', isActive);
  if (isActive) {
    game.restartMusic('d64-theme');
  }
});

game.playSound = function(assetId, isMusic) {
  // return;
  // console.log(assetId, game.gameAudio, PlayGame.gameAudio[1].mode)
  if (!isMusic && game.gameAudio != PlayGame.gameAudio[1].mode) {
    // console.log(`if`,`isMusic=${isMusic}`, `assetId=${assetId}`)
    NativeAudio.play({
      assetId: assetId,
    }).catch((e) => {
      // console.log('_play_ and preload sound for ', assetId, 'failed', e);
      NativeAudio.preload({
        assetId: assetId,
        assetPath: `${PlayGame.capAssetsPath}sounds/${assetId}.mp3`,
        audioChannelNum: 1,
        isUrl: false
      }).catch((e) => {
        // console.log('play and _preload_ sound for ', assetId, 'failed', e);
      })
    });
  }
  else if (isMusic && game.gameAudio.mode == PlayGame.gameAudio[0].mode) {
    // console.log('play music', assetId);
    // console.log(`elif`,`isMusic=${isMusic}`, `assetId=${assetId}`)
    // NativeAudio.play({
    //   assetId: assetId,
    // });
    NativeAudio.setVolume({
      assetId: assetId,
      volume: 0.55, // TODO update with global volume once we add that
    });

    // android doesn't work with only loop, so play it first
    if (Capacitor.getPlatform() == 'android') {
      NativeAudio.play({
        assetId: assetId,
        time: 1
      }).catch((e) => {
        NativeAudio.preload({
          assetId: assetId,
          assetPath: `${PlayGame.capAssetsPath}sounds/${assetId}.mp3`,
          audioChannelNum: 1,
          isUrl: false
        }).catch((e) => {
          // don't worry about it yet
        })
      });
    }
    NativeAudio.loop({
      assetId: assetId
    });

    // TODO see if we want to bring back volume fading as done below
    // NativeAudio.setVolume({
    //   assetId: assetId,
    //   volume: 0.05, // TODO update with global volume once we add that
    // });
    // NativeAudio.play({
    //   assetId: assetId,
    //   time: 0
    // });
    // NativeAudio.loop({
    //   assetId: assetId
    // });
    // let vol = 0.05; // TODO update with global volume once we add that
    // let startingMusic = setInterval(()=>{
    //   vol = vol + 0.02
    //   if (vol < 0.55) {
    //     NativeAudio.setVolume({
    //       assetId: `d64-theme`,
    //       volume: vol
    //     })
    //   }
    //   else {
    //     clearInterval(startingMusic)
    //   }
    // }, 100)
  }
}

game.stopMusic = function() {
  NativeAudio.stop({
    assetId: `d64-theme`,
  });
  // TODO see if we want to bring back volume fading as done below
  // let vol = 0.3; // TODO update with global volume once we add that
  // let stoppingMusic = setInterval(()=>{
  //   vol = vol - 0.02
  //   if (vol > 0.05) {
  //     NativeAudio.setVolume({
  //       assetId: `d64-theme`,
  //       volume: vol
  //     })
  //   }
  //   else {
  //     NativeAudio.stop({
  //       assetId: `d64-theme`,
  //     });
  //     clearInterval(stoppingMusic)
  //   }
  // }, 100)
  
}

game.NumberFormatOptions = {
  notation: "compact",
  compactDisplay: "short",
};
game.getCompactNumber = function(number) {
  const usformatter = Intl.NumberFormat("en-US", game.NumberFormatOptions);
  return usformatter.format(number);
}

game.setGameTheme = (themeName, scene = null) => {
  // console.log('gsGT', themeName, scene);
  themeName = (typeof themeName == 'object') ? themeName.name : themeName;
  let gameThemeObj = { name: themeName, ...PlayGame.themes[themeName] };
  // console.log('gsGT2', gameThemeObj);
  game.gameTheme = gameThemeObj;
  // console.log('gsGT3', game.gameTheme);
  if (game.scene.keys.GameSelection.scene.isActive()) {
    _updateGameTheme(game.scene.keys.GameSelection, gameThemeObj);
  }
  if (game.scene.keys.PlayGame && game.scene.keys.PlayGame.scene.isActive()) {
    _updateGameTheme(game.scene.keys.PlayGame, gameThemeObj);
  }
  if (scene) {
    _updateGameTheme(scene, gameThemeObj);
  }
    // bgImg.setOrigin(0);
    // bgImg.setX(0);
    // bgImg.setY(0);
    // TODO update buttons and anything else during a theme change?
}

const _updateGameTheme = (scene, gameThemeObj) => {
  console.log('_updateGameTheme', scene, gameThemeObj);
  if (scene) {
    scene.bgContainer.removeAll(true);
    let bgImg = new Phaser.GameObjects.Image(scene, 0, 0, gameThemeObj.background.key);
    bgImg.setOrigin(0)
    bgImg.setDepth(-1000000);
    let newScale = Math.max(scene.getWidth() / bgImg.width, scene.getHeight() / bgImg.width); // determine if scaling should fit width or height. hint: use the larger ratio
    bgImg.setScale(newScale);
    bgImg.setAlpha(1);
    scene.bgContainer.add(bgImg);
    console.log('bgContainer###', scene.bgContainer.scale, scene.bgContainer.list[0].scale)
    if (!window.showLandscape()) { // change the background for portrait mode
      console.log('!window.showLandscape()', !window.showLandscape(), scene.bgContainer)
      scene.bgContainer.setRotation(Math.PI / 2);
      scene.bgContainer.setX(scene.getWidth());
    }
  }
}

game.setGameAudio = (mode) => {
  // console.log('setGameAudio', mode);
  let gameAudioObj = PlayGame.gameAudio.find(obj => {
    return obj.mode === mode;
  });
  // console.log(gameAudioObj)
  if (gameAudioObj) {
    game.gameAudio = gameAudioObj;
  }
  if (mode == PlayGame.gameAudio[2].mode || mode == PlayGame.gameAudio[1].mode) {
    game.stopMusic();
  }
  else if (mode == PlayGame.gameAudio[0].mode) {
    game.playSound('d64-theme', true);
    // TODO replace all d64-theme hardcoded references
    //   with a game instance variable
  }
}

game.setGameDeckFace = (scene, deckName) => {
  // console.log('gsGDF', scene, deckName);
  game.gameDeckFace = deckName;
  if (scene.seats) {
    Object.values(scene.seats).forEach((seat) => {
    // console.log('gsGDF seat', seat);
    _setGameDeckFace(seat.hand);
  });
}
  // console.log('setGameDeckFace', this.gameDeckFace);
  [scene.deck, scene.discardPile, scene.discardQueue].forEach((cs)=> {
    // console.log('cs', cs)
    // console.log('gsGDF2', cs)
    _setGameDeckFace(cs);
  });
}

const _setGameDeckFace = (cardset) => {
  // console.log('_sGDF', cardset)
  cardset && cardset.list.forEach((card)=>{
    // console.log(card, `setting to: ${this.gameDeckFace}-${card.cardAttributes.color}${card.cardAttributes.rank}${card.cardAttributes.suit}`)
    card.card = `${game.gameDeckFace}-${card.cardAttributes.color}${card.cardAttributes.rank}${card.cardAttributes.suit}`;
    card.faceUp && card.setTexture(card.card);
  });
}

game.setGameDeckBack = (scene, t) => { // main or alt
  game.gameDeckBack = t;
  // console.log('gsGDB', scene);
  scene.seats && Object.values(scene.seats).forEach((seat) => {
    _setGameDeckBack(seat.hand);
  });
  scene.deck && _setGameDeckBack(scene.deck);
  scene.discardPile && _setGameDeckBack(scene.discardPile);
  scene.discardQueue && _setGameDeckBack(scene.discardQueue);
}

const _setGameDeckBack = (cardset) => {
  cardset && cardset.list.forEach((card)=>{
    // console.log('setting deck back', card)
    card.cardBack = `${game.gameDeckFace}-back-${game.gameDeckBack}`;
    !card.faceUp && card.setTexture(card.cardBack)
  });
}

game.setGameLearningMode = (mode) => {
  let gameLearningModeObj = PlayGame.gameLearningMode.find(obj => {
    return obj.mode === mode;
  });
  // console.log(gameLearningModeObj)
  if (gameLearningModeObj) {
    game.gameLearningMode = gameLearningModeObj;
  }
}

// update avatar
game.setHumanPlayer = (avatar) => {
  // console.log('gsHP', name);
  let humanPlayerObj = _setHumanPlayer(avatar);
  // console.log('HPO', humanPlayerObj);
  if (humanPlayerObj) {
    if (game.scene.keys.GameSelection.scene.isActive()) {
      game.scene.keys.GameSelection.updateAvatar(humanPlayerObj.avatar);
    }
    if (game.scene.keys.PlayGame.scene.isActive()) {
      game.scene.keys.PlayGame.updateAvatar(humanPlayerObj.avatar);
    }
  }
}

game.saveHumanPlayerAvatarToUDS = async (avatar) => {
  let response = {};
  const token = await game.dnrConfig.get("dnrToken");
  const userId = JSON.parse(await game.dnrConfig.get("playerId"));
  const options = {
    url: `https://api.uds.dev.decknroll.com/user/${userId}/avatar`,
    headers: {
      "Content-Type": "application/json"
    },
    method: 'POST',
    data: { avatar: avatar, dnr_token: JSON.parse(token) },
    mode: "no-cors",
    // mode: "no-cors",
    // credentials: "include",
    // webFetchExtra: {
    //   credentials: "include",
    // }
  };
  // console.log("options", options);
  response = await CapacitorHttp.request({ ...options });
}

game.updateDisplayName = async (name) => {
  const token = await game.dnrConfig.get("dnrToken");
  const userId = JSON.parse(await game.dnrConfig.get("playerId"));
  const options = {
    url: `https://api.uds.dev.decknroll.com/user/${userId}/name`,
    headers: {
      "Content-Type": "application/json"
    },
    method: 'POST',
    data: { name: name, dnr_token: JSON.parse(token) },
    mode: "no-cors",
    // mode: "no-cors",
    // credentials: "include",
    // webFetchExtra: {
    //   credentials: "include",
    // }
  };
  // console.log("options", options);
  const response = await CapacitorHttp.request({ ...options });
  game.gamePlayerName = name;
  if (game.scene.keys.GameSelection.scene.isActive()) {
    game.scene.keys.GameSelection.playerInfo.text.setText(name);
  }
  else if (game.scene.keys.PlayGame.scene.isActive()) {
    game.scene.keys.PlayGame.playerInfo.text.setText(name);
  }
  return name;
}

game.getAvatarObjFromAvatarKey = (avatar) => {
  let obj = PlayGame.computerPlayers.find(obj => {
    return obj.avatar === avatar;
  });
  obj = (obj == null) ? PlayGame.computerPlayers[0] : obj;
  return obj;
}

game.getAvatarObjFromName = (name) => {
  return PlayGame.computerPlayers.find(obj => {
    return obj.name === name;
  });
}

const _setHumanPlayer = (avatar) => {
  let hp = game.getAvatarObjFromAvatarKey(avatar);
  // console.log('_sHP', hp, name);
  game.humanPlayer = hp;
  return hp;
}

game.getDnrToken = async function() {
  let token = await game.dnrConfig.get("dnrToken");
  let response = {}
  if (!token) {
    const options = {
      url: 'https://token.auth.decknroll.com/get',
      headers: {},
      method: 'GET',
      mode: "no-cors",
      // credentials: "include",
      // webFetchExtra: {
      //   credentials: "include",
      // }
    };
    response = await CapacitorHttp.request({ ...options })
    // console.log("res res is", response);
  }
  else {
    // renew token
    const options = {
      url: 'https://token.auth.decknroll.com/renew',
      headers: {
        "Content-Type": "application/json"
      },
      method: 'POST',
      data: { dnr_token: JSON.parse(token) },
      // mode: "no-cors",
      // credentials: "include",
      // webFetchExtra: {
      //   credentials: "include",
      // }
    };
    response = await CapacitorHttp.request({ ...options })
  }
  try {
    game.playerId = response.data.payload.sub;
    await game.dnrConfig.set("dnrTokenExp", response.data.payload.exp);
    await game.dnrConfig.set("playerId", game.playerId);
    await game.dnrConfig.set("dnrToken", response.data.dnr_token);
  }
  catch {
    response = null;
  }
  return response;
};

game.needsDnrToken = async function() {
  const now = new Date();
  const tokenDate = new Date(0);
  tokenDate.setUTCSeconds(await game.dnrConfig.get("dnrTokenExp")); // get returns null if dnrTokenExp does not exist, which results in 0 for setUTCSeconds
  tokenDate.setHours(tokenDate.getHours() - 12);
  return tokenDate < now; // is expired or within 12 hours of expiring
}

game.getUserInfoFromUDS = async function() {
  let response = {};
  const token = await game.dnrConfig.get("dnrToken");
  const userId = JSON.parse(await game.dnrConfig.get("playerId"));
  const options = {
    url: `https://api.uds.dev.decknroll.com/user/${userId}`,
    headers: {
      "Content-Type": "application/json"
    },
    method: 'POST',
    data: { dnr_token: JSON.parse(token) },
    mode: "no-cors",
    // mode: "no-cors",
    // credentials: "include",
    // webFetchExtra: {
    //   credentials: "include",
    // }
  };
  // console.log("options", options);
  response = await CapacitorHttp.request({ ...options });
  game.gamePlayerName = response.data.name || "Guest";
  game.gameAvatar = response.data.avatar || "mr_aces";
  game.gameCurrentLevel = response.data.level || 1;
  const ptsInCurrLvl = response.data.points - (game.getPointsAtLevel(game.gameCurrentLevel))
  game.gamePointsInCurrentLevel = Math.max(ptsInCurrLvl, 0);
  // console.log(response);
}

game.setupPlayerInfo = function(scene) {
  return new PlayerInfo({
    scene: scene,
    text: scene.game.gamePlayerName,
    avatar: scene.game.humanPlayer.avatar,
    x: game.positionConfig.playerInfo.x,
    y: game.positionConfig.playerInfo.y
  });
}

/**
 * Returns the position configuration for all game objects
 * If an object has a defined x,y coordinate, it needs to be set here and referenced
 * @param {*} scene 
 * @returns 
 */
game.setPositionConfig = (scene) => {
  let showLandscape = window.showLandscape();
  game.positionConfig = {
    playerInfo: {
      x: scene.getWidth() - 400,
      y: 10
    },
    deck64logo: {
      x: scene.getWidth() / 2,
      y: showLandscape ? 25 : 15
    },
    gameSelectionPanel: {
      x: showLandscape ? 40 : (scene.getWidth() / 2) - 375 / 2, // 375 is width of the icon in each
      scrollMode: showLandscape ? 1 : 0,
      rows: showLandscape ? 1 : 3,
      height: showLandscape ? 550 : 1150,
      width: scene.getWidth(),
    },
    introMessage: {
      x: scene.getWidth() / 2,
      y: showLandscape ? 218 : 350,
    },
    playOnlineButton: {
      x: (scene.getWidth() / 2) - 110,
      y: showLandscape ? scene.getHeight() - 145 : scene.getHeight() - 345,
    },
    backToGameSelectionButton: {
      x: showLandscape ? 150 : (scene.getWidth() / 2) - (175/2),
      y: scene.getHeight() - 100,
    },
    leftControlsContainer: {
      scale: showLandscape ? 1 : .8,
      x: 30,
      y: showLandscape ? scene.getHeight() - 200 : scene.getHeight() - 400
    },
  };
}

game.getPointsAtLevel = (level) => {
  return (((level - 1) * level) / 2) * PlayGame.levelUpFactor;
};

// The following function is from gpt 3.5 (https://chat.openai.com/c/46c19cd2-52fd-47a7-9fc7-100459adac20)
// I have the javascript function
// getPointsAtLevel = (i)=>{return (((i-1)*i)/2)*levelUpFactor;}
// Can you give me a javascript function that gives me the level if I give it points?
game.getLevelFromPoints = (points) => {
  const a = 1;
  const b = -1;
  const c = -2 * (points / PlayGame.levelUpFactor);
  
  // Calculate the discriminant
  const discriminant = Math.sqrt(b * b - 4 * a * c);
  
  // Calculate the two possible levels (roots)
  const level1 = (-b + discriminant) / (2 * a);
  const level2 = (-b - discriminant) / (2 * a);
  
  // We return the level as a positive integer and take the max of level1 and level2
  // as level should be increasing with increasing points.
  return Math.floor(Math.max(level1, level2));
}

game.setGameSpeed = function(speed) {
  // console.log(`setGameSpeed ${speed}`)
  game.gameSpeed = (typeof speed == 'object') ? speed : PlayGame.gameSpeeds[speed];
}