import { Preferences } from '@capacitor/preferences';
export default class Config {
    constructor() {
    }

    getDebug() {
      return true;
    }

    async set(key, value) {
      // this[key] = value;
      await Preferences.set({
        key: key,
        value: JSON.stringify(value)
      });
      // return value;
    }

    async get(key) {
      let val = await Preferences.get({ key: key });
      return val.value // JSON.parse();
    }



    // get2(key) {
    //   Preferences.get({ key: key }).then((v)=>{return v});
    // }

    // async loadFromStorage() {
    //   await Preferences.get({ key: key });
    //   return JSON.parse(ret.value);
    // }

}