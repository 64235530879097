export default class Card extends Phaser.GameObjects.Mesh {

  constructor({
    scene,
    x = 0,
    y = 0,
    card = null, // string e.g. "gn3h"
    cardAttributes = {},
    cardBack = 'back',
    faceUp = false,
    inTransit = false,
    perspective = { width: 360, height: 504 },
    panZ = 18,
    clickFunction = null, 
    clickFunctionData = null,
  }) {
    super( scene, x, y, (faceUp ? card : cardBack) );
    this.card        = card;
    this.cardAttributes = cardAttributes;
    this.cardBack    = cardBack;
    this.faceUp      = faceUp;
    this.perspective = perspective;
    this.addVertices(
      [ -1, 1.4,   1, 1.4,   -1, -1.4,   1, -1.4 ], // vertices
      [  0, 0,     1, 0,      0, 1,      1, 1    ], // uvs
      [ 0, 2, 1,   2, 3, 1 ], // indicies
    );
    this.panZ(panZ);
    this.assignTexture();
    this.clickFunction = clickFunction;
    this.clickFunctionData = clickFunctionData;
    this.scene.add.existing(this);
    return this
  }

  // Changes this mesh's texture depending on if the card is face-up or not
  assignTexture() {
    if (this.faceUp && this.card !== null) { this.setTexture(this.card) }
    else { this.setTexture(this.cardBack); }
    this.setPerspective(this.perspective.width, this.perspective.height);
  }

  // TODO Add lift value to change how big the card looks
  // TODO Optional radomization of eases, x and z
  // TODO Parameterized eases
  // TODO Ratio for how much time is spent in each half of the flip
  // TODO Reverse rotation direction
  flip({
    faceUp=true,
    duration=1000,
    delay=0
  }) {
    if ( faceUp == this.faceUp ) { return; } // No need to flip
    if (this.card === null) { return; } // Can't flip a card with no face
    this.scene.tweens.chain({
      targets: this.modelRotation,
      tweens: [
        {
          x: Math.PI * Phaser.Math.FloatBetween(-0.3, 0.3),
          z: Math.PI * Phaser.Math.FloatBetween(-0.15, 0.15),
          y: -Math.PI / 2,
          ease: 'Back.easeIn',
          //ease: 'Linear',
          onComplete: () => {  this.faceUp = faceUp; this.assignTexture(); },
          duration: duration/2,
          delay: delay,
        },
        {
          x: 0,
          y: 0,
          z: 0,
          ease: 'Bounce.easeOut',
          //ease: 'Linear',
          duration: duration/2,
          onComplete: () => {
          }
        }
      ]
    }).play();
  }

  pos() {
    return {
      x: this.x,
      y: this.y,
      z: this.modelPosition.z,
      selectX: this.modelPosition.x,
      selectY: this.modelPosition.y,
      angle: this.angle,
      depth: this.depth,
    }
  }

  // Sets the card's selected member, and visual properties that change upon selection
  setSelected() {

  }
  
  // Sets the card's selected member, and visual properties that change upon selection
  setCursor() {

  }  

  /*
  static easeBegins = ['Back.easeIn', 'Expo.easeIn']
  static easeEnds = ['Bounce.easeOut','Back.easeOut','Expo.easeOut']
  
  flip() {
    if (this.card) { // the actual type of card (e.g. gn3h) must exist otherwise it can't be flipped
      const self = this;
      this.scene.dbg(`flipping card ${this.card}`)
      const durationBegin = Phaser.Math.Between(200, 350)
      const durationEnd = Phaser.Math.Between(500, 700)
      const easeBegin = Card.easeBegins[Phaser.Math.Between(0, Card.easeBegins.length - 1)]
      const easeEnd = Card.easeEnds[Phaser.Math.Between(0, Card.easeEnds.length - 1)]
      this.scene.tweens.chain({
        targets: self.modelRotation,
        tweens: [{
          x: Math.PI * Phaser.Math.FloatBetween(-0.15, 0.15),
          z: Math.PI * Phaser.Math.FloatBetween(-0.1, 0.1),
          y: -Math.PI / 2,
          ease: easeBegin,
          onComplete: () => {
            if (self.texture.key === 'back') {
              self.setTexture(this.card)
            } else {
              self.setTexture('back');
            }
          },
          duration: durationBegin
        },
        {
          x: 0,
          z: Phaser.Math.FloatBetween(-0.05, 0.05),
          y: 0,
          ease: easeEnd,
          duration: durationEnd
        }]
      }).play();

      this.scene.tweens.chain({
        targets: self.modelPosition,
        tweens: [{
            z: Phaser.Math.FloatBetween(5, 10),
            ease: easeBegin,
            duration: durationBegin
          },
          {
            z: 0,
            ease: easeEnd,
            duration: durationEnd
        }]
      }).play();
    }
  }
  */

  click(args) {
    this.clickFunction.call(this.scene, args, this.clickFunctionData);
  }

  static parseCardIdToAttributes(cardId) { // cardId e.g. rd4h (red 4 of hearts) OR loyal-rd4h
    let li = cardId.lastIndexOf('-'); // for handling most cases where a card id contains the name of the deck theme before a hyphen
    return {
      color: cardId.substring(1+li, 3+li),
      rank: cardId.substring(3+li, 4+li),
      suit: cardId.substring(4+li, 5+li)
    }
  }

}
